const SKELETON_PRODUCT = {
  id: "skeleton",
  image: null,
  translation: {
    name: null,
    slug: null,
  },
  prices: {
    price: null,
    discounted: null,
  },
};

const CARRIER_ID_PICKUP_STORE = 6;
const DEFAULT_LANG = "es";
const DEFAULT_COUNTRY = "ES";
const DEFAULT_REGION = "SPAIN";

const GATEWAYS = {
  Stripe: 1,
  Paypal: 2,
  Braintree: 3,
  AmazonCheckout: 4,
  Klarna: 5,
  Popup: 6,
};

const DEFAULT_CART = {
  cart_id: null,
  start_date: null,
  modified_date: null,
  promo_code: "",
  reward_code: null,
  number_products: 0,
  promo_code_modified: null,
  country: DEFAULT_COUNTRY,
  language: DEFAULT_LANG,
  region: DEFAULT_REGION,
  currency: "EUR",
  totals: {},
  orderlines: [],
  presents: [],
  postalCode: null,
  totalsIsLoading: false,
  meta: {},
  address: {
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    zip_code: "",
    city: "",
    lang: "en",
    prefix_phone: "",
    phone: "",
    country: DEFAULT_COUNTRY,
  },
  origin: "vnext.cocunat.com",
  gateway: GATEWAYS.Stripe,
};

const COUNTRIES = [
  {
    code: "AL",
    name: "Albania",
    lang: "en",
    prefix: "+355",
  },
  {
    code: "AM",
    name: "Armenia",
    lang: "en",
    prefix: "+374",
  },
  {
    code: "AT",
    name: "Austria",
    lang: "en",
    prefix: "+43",
  },
  {
    code: "AU",
    name: "Australia",
    lang: "en",
    prefix: "+61",
  },
  {
    code: "BA",
    name: "Bosnia and Herzegovina",
    lang: "en",
    prefix: "+387",
  },
  {
    code: "BE",
    name: "Belgium",
    lang: "en",
    prefix: "+32",
  },
  {
    code: "BG",
    name: "Bulgaria",
    lang: "en",
    prefix: "+359",
  },
  {
    code: "CA",
    name: "Canada",
    lang: "en",
    prefix: "+1",
  },
  {
    code: "CO",
    name: "Colombia",
    lang: "es",
    prefix: "+57",
  },
  {
    code: "CH",
    name: "Switzerland",
    lang: "en",
    prefix: "+41",
  },
  {
    code: "CY",
    name: "Cyprus",
    lang: "en",
    prefix: "+357",
  },
  {
    code: "CZ",
    name: "Czech Republic",
    lang: "en",
    prefix: "+420",
  },
  {
    code: "DE",
    name: "Germany",
    lang: "de",
    prefix: "+49",
  },
  {
    code: "DK",
    name: "Denmark",
    lang: "en",
    prefix: "+45",
  },
  {
    code: "EE",
    name: "Estonia",
    lang: "en",
    prefix: "+372",
  },
  {
    code: "ES",
    name: "Spain (España)",
    lang: "es",
    prefix: "+34",
  },
  {
    code: "FI",
    name: "Finland",
    lang: "en",
    prefix: "+358",
  },
  {
    code: "FO",
    name: "Faroe Islands",
    lang: "en",
    prefix: "+298",
  },
  {
    code: "FR",
    name: "France",
    lang: "fr",
    prefix: "+33",
  },
  {
    code: "GB",
    name: "United Kingdom",
    lang: "en",
    prefix: "+44",
  },
  {
    code: "GE",
    name: "Georgia",
    lang: "en",
    prefix: "+995",
  },
  {
    code: "GL",
    name: "Greenland",
    lang: "en",
    prefix: "+299",
  },
  {
    code: "GR",
    name: "Greece",
    lang: "en",
    prefix: "+30",
  },
  {
    code: "HR",
    name: "Croatia",
    lang: "en",
    prefix: "+385",
  },
  {
    code: "HU",
    name: "Hungary",
    lang: "en",
    prefix: "+36",
  },
  {
    code: "IE",
    name: "Ireland",
    lang: "en",
    prefix: "+353",
  },
  {
    code: "IM",
    name: "Isle of Man",
    lang: "en",
    prefix: "+44",
  },
  {
    code: "IS",
    name: "Iceland",
    lang: "en",
    prefix: "+354",
  },
  {
    code: "IT",
    name: "Italy",
    lang: "it",
    prefix: "+39",
  },
  {
    code: "LI",
    name: "Liechtenstein",
    lang: "en",
    prefix: "+423",
  },
  {
    code: "LT",
    name: "Lithuania",
    lang: "en",
    prefix: "+370",
  },
  {
    code: "LU",
    name: "Luxembourg",
    lang: "en",
    prefix: "+352",
  },
  {
    code: "LV",
    name: "Latvia",
    lang: "en",
    prefix: "+371",
  },
  {
    code: "MC",
    name: "Monaco",
    lang: "en",
    prefix: "+377",
  },
  {
    code: "MD",
    name: "Moldova",
    lang: "en",
    prefix: "+373",
  },
  {
    code: "ME",
    name: "Montenegro",
    lang: "en",
    prefix: "+382",
  },
  {
    code: "MK",
    name: "Macedonia",
    lang: "en",
    prefix: "+389",
  },
  {
    code: "MT",
    name: "Malta",
    lang: "en",
    prefix: "+356",
  },
  {
    code: "NL",
    name: "Netherlands",
    lang: "en",
    prefix: "+31",
  },
  {
    code: "NO",
    name: "Norway",
    lang: "en",
    prefix: "+47",
  },
  {
    code: "NZ",
    name: "New Zealand",
    lang: "en",
    prefix: "+64",
  },
  {
    code: "PL",
    name: "Poland",
    lang: "en",
    prefix: "+48",
  },
  {
    code: "PT",
    name: "Portugal",
    lang: "en",
    prefix: "+351",
  },
  {
    code: "RO",
    name: "Romania",
    lang: "en",
    prefix: "+40",
  },
  {
    code: "RS",
    name: "Serbia",
    lang: "en",
    prefix: "+381",
  },
  {
    code: "SE",
    name: "Sweden",
    lang: "en",
    prefix: "+46",
  },
  {
    code: "SI",
    name: "Slovenia",
    lang: "en",
    prefix: "+386",
  },
  {
    code: "SJ",
    name: "Svalbard and Jan Mayen",
    lang: "en",
    prefix: "+47",
  },
  {
    code: "SK",
    name: "Slovakia",
    lang: "en",
    prefix: "+421",
  },
  {
    code: "SM",
    name: "San Marino",
    lang: "en",
    prefix: "+378",
  },
  {
    code: "US",
    name: "United States",
    lang: "en",
    prefix: "+1",
  },
  {
    code: "XK",
    name: "Kosovo",
    lang: "en",
    prefix: "+383",
  },
  {
    code: "PE",
    name: "Peru",
    lang: "es",
    prefix: "+51",
  },
  {
    code: "PH",
    name: "Phillipines",
    lang: "en",
    prefix: "+63",
  },
  {
    code: "IL",
    name: "Israel",
    lang: "en",
    prefix: "+972",
  },
  {
    code: "SA",
    name: "Arabia Saudi",
    lang: "en",
    prefix: "+966",
  },
  {
    code: "PR",
    name: "Puerto Rico",
    lang: "en",
    prefix: "+1",
  },
];
const STATES = {
  US: [
    {
      code: "AL",
      name: "Alabama",
    },
    {
      code: "AK",
      name: "Alaska",
    },
    {
      code: "AZ",
      name: "Arizona",
    },
    {
      code: "AR",
      name: "Arkansas",
    },
    {
      code: "CA",
      name: "California",
    },
    {
      code: "CO",
      name: "Colorado",
    },
    {
      code: "CT",
      name: "Connecticut",
    },
    {
      code: "DC",
      name: "District of Columbia",
    },
    {
      code: "DE",
      name: "Delaware",
    },
    {
      code: "FL",
      name: "Florida",
    },
    {
      code: "GA",
      name: "Georgia",
    },
    {
      code: "HI",
      name: "Hawaii",
    },
    {
      code: "ID",
      name: "Idaho",
    },
    {
      code: "IL",
      name: "Illinois",
    },
    {
      code: "IN",
      name: "Indiana",
    },
    {
      code: "IA",
      name: "Iowa",
    },
    {
      code: "KS",
      name: "Kansas",
    },
    {
      code: "KY",
      name: "Kentucky",
    },
    {
      code: "LA",
      name: "Louisiana",
    },
    {
      code: "ME",
      name: "Maine",
    },
    {
      code: "MD",
      name: "Maryland",
    },
    {
      code: "MA",
      name: "Massachusetts",
    },
    {
      code: "MI",
      name: "Michigan",
    },
    {
      code: "MN",
      name: "Minnesota",
    },
    {
      code: "MS",
      name: "Mississippi",
    },
    {
      code: "MO",
      name: "Missouri",
    },
    {
      code: "MT",
      name: "Montana",
    },
    {
      code: "NE",
      name: "Nebraska",
    },
    {
      code: "NV",
      name: "Nevada",
    },
    {
      code: "NH",
      name: "New Hampshire",
    },
    {
      code: "NJ",
      name: "New Jersey",
    },
    {
      code: "NM",
      name: "New Mexico",
    },
    {
      code: "NY",
      name: "New York",
    },
    {
      code: "NC",
      name: "North Carolina",
    },
    {
      code: "ND",
      name: "North Dakota",
    },
    {
      code: "OH",
      name: "Ohio",
    },
    {
      code: "OK",
      name: "Oklahoma",
    },
    {
      code: "OR",
      name: "Oregon",
    },
    {
      code: "PA",
      name: "Pennsylvania",
    },
    {
      code: "RI",
      name: "Rhode Island",
    },
    {
      code: "SC",
      name: "South Carolina",
    },
    {
      code: "SD",
      name: "South Dakota",
    },
    {
      code: "TN",
      name: "Tennessee",
    },
    {
      code: "TX",
      name: "Texas",
    },
    {
      code: "UT",
      name: "Utah",
    },
    {
      code: "VT",
      name: "Vermont",
    },
    {
      code: "VA",
      name: "Virginia",
    },
    {
      code: "WA",
      name: "Washington",
    },
    {
      code: "WV",
      name: "West Virginia",
    },
    {
      code: "WI",
      name: "Wisconsin",
    },
    {
      code: "WY",
      name: "Wyoming",
    },
  ],
};
const BLANK_ADDRESS = {
  first_name: "",
  last_name: "",
  email: "",
  address: "",
  zip_code: "",
  city: "",
  prefix_phone: "",
  phone: "",
  country: "",
};

const WORKER_CHECKOUTS = "https://checkouts.cocunat.workers.dev/";
const AWS_CART_CHECKOUTS = "https://cocunatapi.net/checkout/carts/";
const LEADS_URL = "https://cocunatapi.net/leads-api/create";

const DEFAULT_DISCARD_CONDITIONS = {
  visible: false,
  active: false,
  is_variation: true,
};

const PAYPAL_ACCEPTED_CURRENCIES = [
  // "AUD",
  // "BRL",
  // "CAD",
  // "CHF",
  // "CNY",
  // "CZK",
  // "DKK",
  "EUR",
  "GBP",
  // "HKD",
  // "HUF",
  // "ILS",
  // "JPY",
  // "MXN",
  // "MYR",
  // "NOK",
  // "NZD",
  // "PHP",
  // "PLN",
  // "RUB",
  // "SEK",
  // "SGD",
  // "THB",
  // "TWD",
  "USD",
];
const AMAZON_ACCEPTED_CURRENCIES = [
  "AUD",
  "CHF",
  "DKK",
  "EUR",
  "GBP",
  "HKD",
  "JPY",
  "NOK",
  "NZD",
  "SEK",
  "USD",
  "ZAR",
];
const PRODUCTS_API_BASE_URL =
  process.env.REACT_APP_PRODUCTS_API_BASE_URL ||
  "https://proxy.cocunat.com/products-new/products/";
const CART_API_BASE_URL =
  process.env.REACT_APP_CART_API_BASE_URL || "https://proxy.cocunat.com/carts/";
const SUBSCRIPTION_API_URL =
  process.env.REACT_APP_SUBSCRIPTION_API_URL ||
  "https://cocunatapi.net/subscriptions/api/";
const USERS_API_URL =
  process.env.REACT_APP_USERS_API_URL || "https://cocunatapi.net/users-api/";

const REGIONS_API_URL =
  process.env.REACT_APP_REGIONS_API_URL || "https://proxy.cocunat.com/regions/";

const TRIAL_PRODUCTS = [
  53300, 53308, 53301, 53302, 53303, 53305, 94, 95, 96, 53306, 53304,
];

const LAST_UNITS_PRODUCTS = [];
const KILLING_IT_PRODUCTS = [];
const HIGH_DEMAND_PRODUCTS = [53299, 53336, 53337, 53338, 53339, 53340, 53341];
const ORDERLINE_TYPES = {
  POST_CHECKOUT: "post_checkout",
  TRIAL: "trial",
  PRE_CHECKOUT: "pre_checkout",
};
const CONSTANTS = {
  API_BASE_URL: "https://rest-api.cocunat.com/v2/",
  SUBSCRIPTION_API_URL: SUBSCRIPTION_API_URL,
  USERS_API_URL: USERS_API_URL,
  PRODUCTS_API_BASE_URL: PRODUCTS_API_BASE_URL,
  REGIONS_API_BASE_URL: REGIONS_API_URL,
  CART_API_BASE_URL: CART_API_BASE_URL,
  DEFAULT_CART: DEFAULT_CART,
  SKELETON_PRODUCT: SKELETON_PRODUCT,
  DEFAULT_LANG: DEFAULT_LANG,
  CARRIER_ID_PICKUP_STORE: CARRIER_ID_PICKUP_STORE,
  COUNTRIES: COUNTRIES,
  DEFAULT_COUNTRY: DEFAULT_COUNTRY,
  BLANK_ADDRESS: BLANK_ADDRESS,
  GOURMET_ACTIVE: [53009],
  SUBSCRIPTION_TYPES: {
    GOURMET: "GOURMET",
    SIMPLE: "SIMPLE",
    GOURMET_TRIALS: "GOURMET-TRIALS",
  },
  STORE_ADDRESS: {
    address: "Rambla Catalunya 89 local 1 (Mallorca 247)",
    city: "Barcelona",
    zip_code: "08008",
    country: "ES",
    prefix_phone: "+34",
    phone: "911980581",
  },
  GATEWAYS: GATEWAYS,
  WORKER_CHECKOUTS: WORKER_CHECKOUTS,
  AWS_CART_CHECKOUTS: AWS_CART_CHECKOUTS,
  DEFAULT_DISCARD_CONDITIONS: DEFAULT_DISCARD_CONDITIONS,
  STATES: STATES,
  PAYPAL_ACCEPTED_CURRENCIES: PAYPAL_ACCEPTED_CURRENCIES,
  AMAZON_ACCEPTED_CURRENCIES: AMAZON_ACCEPTED_CURRENCIES,
  TRIAL_PRODUCTS: TRIAL_PRODUCTS,
  LEADS_URL: LEADS_URL,
  LAST_UNITS_PRODUCTS: LAST_UNITS_PRODUCTS,
  KILLING_IT_PRODUCTS: KILLING_IT_PRODUCTS,
  HIGH_DEMAND_PRODUCTS: HIGH_DEMAND_PRODUCTS,
  ORDERLINE_TYPES: ORDERLINE_TYPES,
  UPSELL_DISCOUNT: {
    
    /* DUNNO WATAFEK THIS IS BUT OKE!  FOUND OUT... IT IS CBF DO Not REMOVEEAEDFAJOFNAOIP*/
    /*"gid://shopify/ProductVariant/41955070509221": 0.2,
    "gid://shopify/ProductVariant/47765267644762": 0.2,
    "gid://shopify/ProductVariant/47765267349850": 0.2,

    // GENERATED
    "gid://shopify/ProductVariant/41955092463781": 0.15,
    "gid://shopify/ProductVariant/41955100524709": 0.2,
    "gid://shopify/ProductVariant/41955112059045": 0.2,
    "gid://shopify/ProductVariant/41955269509285": 0.2,
    "gid://shopify/ProductVariant/41958282461349": 0.25,
    "gid://shopify/ProductVariant/41958286590117": 0.4,
    "gid://shopify/ProductVariant/41958791184549": 0.2,
    "gid://shopify/ProductVariant/42144645677221": 0.4,
    "gid://shopify/ProductVariant/42246092292261": 0.2,
    "gid://shopify/ProductVariant/42266763198629": 0.2,
    "gid://shopify/ProductVariant/42598420676781": 0.2,
    "gid://shopify/ProductVariant/42598420709549": 0.2,
    "gid://shopify/ProductVariant/42598420742317": 0.2,
    "gid://shopify/ProductVariant/42598420775085": 0.2,
    "gid://shopify/ProductVariant/42598422970541": 0.2,
    "gid://shopify/ProductVariant/42598429130925": 0.2,
    "gid://shopify/ProductVariant/42598434275501": 0.2,
    "gid://shopify/ProductVariant/42598434308269": 0.2,
    "gid://shopify/ProductVariant/42598434341037": 0.2,
    "gid://shopify/ProductVariant/42598434373805": 0.2,
    "gid://shopify/ProductVariant/42598435127469": 0.15,
    "gid://shopify/ProductVariant/42598436503725": 0.3,
    "gid://shopify/ProductVariant/42598436536493": 0.3,
    "gid://shopify/ProductVariant/42598436569261": 0.3,
    "gid://shopify/ProductVariant/42598438207661": 0.1,
    "gid://shopify/ProductVariant/42598438699181": 0.2,
    "gid://shopify/ProductVariant/42598439714989": 0.2,
    "gid://shopify/ProductVariant/42598439878829": 0.2,
    "gid://shopify/ProductVariant/42598444531885": 0.2,
    "gid://shopify/ProductVariant/42598449152173": 0.2,
    "gid://shopify/ProductVariant/42598449643693": 0.2,
    "gid://shopify/ProductVariant/42598452494509": 0.2,
    "gid://shopify/ProductVariant/42598452560045": 0.2,
    "gid://shopify/ProductVariant/42598454952109": 0.2,
    "gid://shopify/ProductVariant/42598456623277": 0.2,
    "gid://shopify/ProductVariant/42598456852653": 0.2,
    "gid://shopify/ProductVariant/42598459801773": 0.2,
    "gid://shopify/ProductVariant/42598459834541": 0.2,
    "gid://shopify/ProductVariant/42598459867309": 0.2,
    "gid://shopify/ProductVariant/42598460260525": 0.2,
    "gid://shopify/ProductVariant/42598462423213": 0.2,
    "gid://shopify/ProductVariant/42598463340717": 0.2,
    "gid://shopify/ProductVariant/42598469992621": 0.2,
    "gid://shopify/ProductVariant/42598473334957": 0.2,
    "gid://shopify/ProductVariant/42598482444461": 0.25,
    "gid://shopify/ProductVariant/42598482542765": 0.3,
    "gid://shopify/ProductVariant/42598483427501": 0.2,
    "gid://shopify/ProductVariant/42598485328045": 0.3,
    "gid://shopify/ProductVariant/42598489456813": 0.2,
    "gid://shopify/ProductVariant/42598492733613": 0.2,
    "gid://shopify/ProductVariant/42598500466861": 0.2,
    "gid://shopify/ProductVariant/42598512197805": 0.2,
    "gid://shopify/ProductVariant/42598515802285": 0.2,
    "gid://shopify/ProductVariant/42598515867821": 0.3,
    "gid://shopify/ProductVariant/42598518128813": 0.2,
    "gid://shopify/ProductVariant/42598518882477": 0.2,
    "gid://shopify/ProductVariant/42598519242925": 0.25,
    "gid://shopify/ProductVariant/42598519308461": 0.2,
    "gid://shopify/ProductVariant/42598520357037": 0.4,
    "gid://shopify/ProductVariant/42730970775725": 0.2,
    "gid://shopify/ProductVariant/42730971594925": 0.2,
    "gid://shopify/ProductVariant/42730972741805": 0.3,
    "gid://shopify/ProductVariant/44020841513255": 0.2,
    "gid://shopify/ProductVariant/44020843872551": 0.2,
    "gid://shopify/ProductVariant/44020844069159": 0.2,
    "gid://shopify/ProductVariant/44020844462375": 0.3,
    "gid://shopify/ProductVariant/44020847313191": 0.2,
    "gid://shopify/ProductVariant/44020848754983": 0.25,
    "gid://shopify/ProductVariant/44020850884903": 0.25,
    "gid://shopify/ProductVariant/44020851212583": 0.2,
    "gid://shopify/ProductVariant/44020858650919": 0.2,
    "gid://shopify/ProductVariant/44020859502887": 0.2,
    "gid://shopify/ProductVariant/44020860059943": 0.2,
    "gid://shopify/ProductVariant/44020860584231": 0.2,
    "gid://shopify/ProductVariant/44020861403431": 0.2,
    "gid://shopify/ProductVariant/44020862877991": 0.15,
    "gid://shopify/ProductVariant/44020868284711": 0.2,
    "gid://shopify/ProductVariant/44020869136679": 0.2,
    "gid://shopify/ProductVariant/44020869759271": 0.2,
    "gid://shopify/ProductVariant/44020870381863": 0.15,
    "gid://shopify/ProductVariant/44020871364903": 0.2,
    "gid://shopify/ProductVariant/44020871954727": 0.2,
    "gid://shopify/ProductVariant/44020872085799": 0.2,
    "gid://shopify/ProductVariant/44020872380711": 0.2,
    "gid://shopify/ProductVariant/44020872904999": 0.2,
    "gid://shopify/ProductVariant/44020872937767": 0.2,
    "gid://shopify/ProductVariant/44020872970535": 0.15,
    "gid://shopify/ProductVariant/44198221283623": 0.2,
    "gid://shopify/ProductVariant/44784096051495": 0.2,
    "gid://shopify/ProductVariant/44784105685287": 0.3,
    "gid://shopify/ProductVariant/45315834380583": 0.25,
    "gid://shopify/ProductVariant/46429964239143": 0.2,
    "gid://shopify/ProductVariant/46771193151834": 0.2,
    "gid://shopify/ProductVariant/47578631111002": 0.35,
    "gid://shopify/ProductVariant/47578632716634": 0.4,
    "gid://shopify/ProductVariant/47578636026202": 0.5,
    "gid://shopify/ProductVariant/47821272776999": 0.35,
    "gid://shopify/ProductVariant/47904974700890": 0.2,
    "gid://shopify/ProductVariant/47904976798042": 0.4,
    "gid://shopify/ProductVariant/48027215266087": 0.2,
    "gid://shopify/ProductVariant/48127353323866": 0.2,
    "gid://shopify/ProductVariant/48127353356634": 0.2,
    "gid://shopify/ProductVariant/48127353389402": 0.2,
    "gid://shopify/ProductVariant/48127353422170": 0.2,
    "gid://shopify/ProductVariant/48164666114343": 0.3,
    "gid://shopify/ProductVariant/48164743545127": 0.2,
    "gid://shopify/ProductVariant/48333540589914": 0.3,
    "gid://shopify/ProductVariant/48399538192679": 0.2,
    "gid://shopify/ProductVariant/48473363808602": 0.2,
    "gid://shopify/ProductVariant/48473368166746": 0.25,
    "gid://shopify/ProductVariant/48473374359898": 0.2,
    "gid://shopify/ProductVariant/48473380192602": 0.25,
    "gid://shopify/ProductVariant/48689809064231": 0.25,
    "gid://shopify/ProductVariant/48689868833063": 0.25,
    "gid://shopify/ProductVariant/48966308102439": 0.2,
    "gid://shopify/ProductVariant/48966308364583": 0.2,
    "gid://shopify/ProductVariant/48966308561191": 0.2,
    "gid://shopify/ProductVariant/48966311510311": 0.3,
    "gid://shopify/ProductVariant/48966315933991": 0.25,
    "gid://shopify/ProductVariant/49161490006311": 0.4,
    "gid://shopify/ProductVariant/49569584972071": 0.2,
    "gid://shopify/ProductVariant/49889878311258": 0.3,
    "gid://shopify/ProductVariant/49890220802394": 0.35,
    "gid://shopify/ProductVariant/49980952052058": 0.3,
    "gid://shopify/ProductVariant/49981061955930": 0.15,*/
    default: 0.1,
  },
};

export default CONSTANTS;
